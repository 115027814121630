<template>
  <div>
    <div v-if="!showThankyouMessage">
      <div class="card-body bad-feedback-screen">
        <div class="bad-feedback-text-wrapper">
          <div class="feedback-title">What could we have done better?</div>
        </div>
        <div class="feedback-input-wrapper is-bad-review">
          <el-input
            type="textarea"
            placeholder="Let us know how we can improve in the future"
            rows="5"
            :value="value"
            @input="(val) => $emit('input', val)"
          />
        </div>
        <div class="action-buttons-wrapper">
          <div class="inner-wrapper">
            <el-button @click.prevent="$emit('back')" type="secondary"> Back </el-button>
            <el-button @click.prevent="$emit('submit-feedback')" type="primary" :disabled="submitBtnDisabled">
              Share
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div key="thankyou_wrapper" class="thank-you-message-wrapper">
        <div class="icon-wrapper-background">
          <custom-icon :icon="'circle-check'" :size="32" :color="'#18B47D'" />
        </div>
        <div class="wrapper-title">Thank you for your feedback!</div>
        <div class="wrapper-text">
          We take your feedback very seriously. We've passed this along to our team so we can make your experience
          better in the future.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NegativeFeedbackLeaveMessage',
  props: {
    value: {
      type: String,
      required: true,
    },
    sendingReview: {
      type: Boolean,
      default: false,
    },
    submitBtnDisabled: {
      type: Boolean,
      default: false,
    },
    showThankyouMessage: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$posthog.capture('feedback', { category: 'negative_feedback' });
  },
};
</script>
<style lang="scss">
.icon-wrapper-background {
  background-color: #b9f9e2;
  border-radius: 8px;
  padding: 8px;
  display: inline-block;
  margin-bottom: 8px;
}
</style>
