<template>
  <div class="feedback-main-body">
    <div class="feedback-title">How was your experience?</div>
    <p>Thank you for visiting us! Please take a moment to leave us a review.</p>
    <div class="google-icon-text-wrapper" @click.prevent="openReviewLink">
      <img src="@/assets/GoogleIconColorNoBg.png" alt="Google Icon" style="height: 32px; width: 32px" />
      <div class="icon-button-text">Leave us a Google review</div>
    </div>

    <p>If you did not have a good experience please let us know and we’ll make this right.</p>
    <button type="button" class="primary-btn full-width destructive" @click.prevent="$emit('how-can-we-improve')">
      <span>Tell us about your issue</span>
    </button>
    <p v-if="accountName" class="feedback-bottom-text">Sincerely, all of us here at {{ accountName }}</p>
  </div>
</template>
<script>
export default {
  name: 'HowWasYourExperience',
  props: {
    accountName: {
      type: String,
      required: false,
      default: '',
    },
    reviewLink: {
      type: String,
      required: false,
      default: '',
    },
  },
  mounted() {
    this.$posthog.capture('feedback', { category: 'google_review' });
  },
  methods: {
    openReviewLink() {
      this.$posthog.capture('feedback', { category: 'google_review', action: 'google_review_link_clicked' });
      window.location.href = this.reviewLink;
    },
  },
};
</script>

<style lang="scss">
.feedback-main-body {
  h3 {
    margin-bottom: 20px;
  }
  p {
    color: #2d3139; // Gray 800
    font-size: 16px;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 20px !important;
  }
  .icon-button-text {
    font-size: 16px;
    font-weight: 400;
    color: white;
    font-size: 14px;
  }
  .google-icon-text-wrapper {
    background-color: #18b47d; // Green 500
    height: 64px;
    min-width: 343px;
    border-radius: 8px;
    border: 1px #e3e5e8 solid;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .feedback-bottom-text {
    margin-top: 20px;
  }
}
</style>
