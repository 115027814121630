<template>
  <div class="feedback-layout">
    <transition name="fade-faster">
      <div v-if="!loading" class="feedback-card">
        <transition name="fade-fastest" mode="out-in">
          <how-was-your-experience
            v-if="showMainFeedback"
            :account-name="shopInfo?.name"
            :review-link="shopInfo.gmb_review_link"
            @how-can-we-improve="handleSendFeedbackBtnClick"
          />

          <negative-feedback-leave-message
            v-else
            v-model="feedback"
            :submit-btn-disabled="!feedback || sendingReview"
            :show-thankyou-message="showThankyouMessage"
            @back="handleBackBtnClick"
            @submit-feedback="sendFeedback"
          />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import NegativeFeedbackLeaveMessage from '@/components/feedback/NegativeFeedbackLeaveMessage';
import HowWasYourExperience from '@/components/feedback/HowWasYourExperience';
import ShopServicesService from '@/services/ShopServicesService';
import ReviewsService from '@/services/ReviewsService';

export default {
  name: 'Feedback',
  components: {
    HowWasYourExperience,
    NegativeFeedbackLeaveMessage,
  },
  data() {
    return {
      loading: true,
      shopInfo: null,
      selectedEvaluation: '',
      showMainFeedback: true,
      feedback: '',
      sendingReview: false,
      showThankyouMessage: false,
    };
  },
  mounted() {
    this.setFeedbackGlobalBackgroundColor();
    this.fetchAccountData();
  },
  methods: {
    changePageTitle(title) {
      document.title = title;
    },
    setFeedbackGlobalBackgroundColor() {
      document.querySelector('body').style.backgroundColor = '#F9F9FB';
      document.querySelector('.app-container').style.backgroundColor = '#F9F9FB';
    },
    async fetchAccountData() {
      const { shop } = this.$route.query;
      if (!shop) return;

      const {
        data: { data },
      } = await ShopServicesService.get.shopInfo(shop);
      this.changePageTitle(data.name);
      this.loading = false;
      this.shopInfo = data;
    },
    handleBackBtnClick() {
      this.showMainFeedback = true;
    },
    handleSendFeedbackBtnClick() {
      this.showMainFeedback = false;
    },
    async sendFeedback() {
      const { shop, customer_slug: customerSlug } = this.$route.query;
      if (!shop || !customerSlug) return;
      this.sendingReview = true;
      await ReviewsService.post.reviews(shop, 'feedback', this.feedback || '', customerSlug);
      this.sendingReview = false;
      this.showThankyouMessage = true;
      this.$posthog.capture('feedback', {
        category: 'negative_feedback',
        action: 'feedback_submitted',
        account_name: this.shopInfo.name,
        account_slug: this.shopInfo.slug,
      });
    },
  },
};
</script>
