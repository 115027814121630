import apiClient from '@/client';

export default {
  post: {
    reviews: (account, mood, message, customer_slug) => {
      return apiClient.post(`/public/accounts/${account}/reviews`, {
        mood,
        message,
        customer_slug
      });
    }
  }
};